import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SignUpDataService {
  private userData = new BehaviorSubject<any>({});
  currentUser: any = this.userData.asObservable();

  private languageSubject = new BehaviorSubject<string>("en");
  currentLanguage = this.languageSubject.asObservable();

  updateUser(data: any) {
    this.userData.next(data);
  }

  updateLanguage(lang: string) {
    this.languageSubject.next(lang);
  }
}
